import * as THREE from "three";
import React, { useEffect, useState, useRef, Suspense } from "react";
import Auth from "../auth/Auth";
import styles from "./Core.module.css";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "../../app/store";
import { useAnimations, useGLTF } from '@react-three/drei'
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader'
import { withStyles } from "@material-ui/core/styles";
import { Canvas, extend } from "@react-three/fiber";
import { Provider } from 'jotai';
import {
  Button,
  Grid,
  Avatar,
  Badge,
  CircularProgress,
  Box
} from "@material-ui/core";
import {
  editNickname,
  selectProfile,
  selectIsLoadingAuth,
  setOpenSignIn,
  resetOpenSignIn,
  setOpenTos,
  setOpenProfile,
  resetOpenProfile,
  fetchAsyncGetMyProf,
  fetchAsyncGetProfs,
  setOpenDescri,
  resetOpenDescri,
} from "../auth/authSlice";
import {
  setOpenAvatar,
  resetOpenAvatar,
  fetchAsyncGetMyAvatar,
  selectAvatar,
} from "../avatar/avatarSlice"
import {
  selectPosts,
  fetchAsyncGetPosts,
} from "../post/postSlice";
import {
  setOpenChat,
  resetOpenChat,
  fetchAsyncGetMyChat
} from "../chat/chatSlice";
import {
  setOpenLocation,
  fetchAsyncPostGeo,
  fetchLocationStart,
  fetchLocationEnd
} from "../core/locationSlice";
import { PROPS_AVATAR } from "../types";
import Post from "../post/Post";
import PostChat from "../chat/PostChat";
import EditProfile from "./EditProfile";
import SetProfile from "../auth/SetProfile";
import Tos from "../auth/Tos";
import SetAvatar from "../avatar/SetAvatar";
import Descri from "../auth/Descri";
import { useAuth0 } from "@auth0/auth0-react";
extend({ Canvas })

interface GMapWindow extends Window {
  adsbygoogle: any;
 }
  declare const window: GMapWindow;

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "50%",
      height: "50%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))(Badge);
type GLTFResult = GLTF & {
  nodes: {
    mesh_0: THREE.SkinnedMesh
    Hips: THREE.Bone
  }
  materials: {
    ['CATS Baked']: THREE.MeshStandardMaterial
  }
}
type ActionName = "Armature.001|mixamo.com|Layer0";
type GLTFActions = Record<ActionName, THREE.AnimationAction>;
const Setcoremodels: React.FC = () => {
  const avatar = useSelector(selectAvatar);
  return (
    <>
      <Box style={{ height: "50vw", width: "90vw" }}>
        <Canvas>
          <ambientLight intensity={1} />
            <Suspense fallback={null}>
              <group 
                rotation={[0.3, -0.5, 0]}
                scale={[3, 3, 3]}
                position={[0, -2.5, 0]} >
                  <Provider>
                    <Coremodel url={avatar.movies + '0709.glb'}  />
                  </Provider>
              </group>
            </Suspense>
        </Canvas>
      </Box>
    </>
  );
};
const Coremodel: React.FC<PROPS_AVATAR> = ({ url, ...props }) => {
  const group = useRef<THREE.Group>();
  const { nodes, materials, animations } = useGLTF(url) as GLTFResult
  const { actions } = useAnimations<GLTFActions>(animations, group)
  const ActionName = "Armature.001|mixamo.com|Layer0";
  useEffect(() => {
    actions[ActionName].reset().play().setLoop(1, 5)
  });
  
  return (
    <>
      <group ref={group} {...props} dispose={null}>
        <group name="Armature" position={[-0.06, 0.02, 0.04]} rotation={[-0.06, 0.07, -0.06]}>
          <skinnedMesh
            name="mesh_0"
            geometry={nodes.mesh_0.geometry}
            material={materials['CATS Baked']}
            skeleton={nodes.mesh_0.skeleton}
            morphTargetDictionary={nodes.mesh_0.morphTargetDictionary}
            morphTargetInfluences={nodes.mesh_0.morphTargetInfluences}
            position={[-0.7, -0.02, -0.24]}
            scale={[1, 1, 1]}
          />
          <primitive object={nodes.Hips} />
        </group>
      </group>
    </>
  );
};

useGLTF.preload('/fedefault0709.glb')
const Adsense: React.FC = () => {
  useEffect(() => {
    if (window.adsbygoogle && process.env.NODE_ENV !== "development") {
        window.adsbygoogle.push({});
    }
  }, [])
  return(
    <ins className="adsbygoogle"
      style={{ "display": "block" }}
      data-ad-client={process.env.REACT_APP_GOOGLE_AD_CLIENT}
      data-ad-slot={process.env.REACT_APP_GOOGLE_AD_SLOT}
      data-ad-format="auto"
      data-full-width-responsive="true">
    </ins>
  );
};

const ErrorText = () => (
  <p className="App-error-text">geolocation IS NOT available</p>
);

const Core: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const profile = useSelector(selectProfile);
  const posts = useSelector(selectPosts);
  const isLoadingAuth = useSelector(selectIsLoadingAuth);
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [isAvailable, setAvailable] = useState(false);
  const [userLocation, setUserLocation] = useState(1);
  const isFirstRef = useRef(true);
  const { logout } = useAuth0();
  
  useEffect(() => {
    const fetchBootLoader = async () => {
      if (localStorage.localJWT) {
        dispatch(resetOpenSignIn());
        const result = await dispatch(fetchAsyncGetMyProf());
        if (fetchAsyncGetMyProf.rejected.match(result)) {
          dispatch(setOpenSignIn()); 
          return null;
        }
        await dispatch(fetchAsyncGetMyAvatar());
        await dispatch(fetchAsyncGetPosts());
        await dispatch(fetchAsyncGetProfs());
      }
    };
    fetchBootLoader();
  }, [dispatch]);

  const getCurrentPosition = () => {
    navigator.geolocation.getCurrentPosition(position => {
      const { latitude, longitude } = position.coords;
      setLatitude(latitude);
      setLongitude(longitude);
    });
  };
  
  const PostCurrentPosition = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    const packet = { userLocation: userLocation, latitude: latitude, longitude: longitude };
    await dispatch(fetchLocationStart());
    await dispatch(fetchAsyncPostGeo(packet));
    await dispatch(fetchLocationEnd());
    setUserLocation(0);
    };

  useEffect(() => {
    isFirstRef.current = false;
    if ('geolocation' in navigator) {
      setAvailable(true);
    }
  }, [isAvailable]);

  if (isFirstRef.current) return <div className="App">Loading...</div>;
  const refreshPage = () => {
    window.location.reload(false);
  }

  
  return (
    <div>
      <Auth />
      <EditProfile />
      <PostChat />
      <SetProfile />
      <Tos />
      <SetAvatar />
      <Descri />
      <div className={styles.cores}>
        <div className={styles.core_header}>
          <div className={styles.core_uurococontener}>
            <button className={styles.core_uurocologo}      
              onClick={async(e) => {
              dispatch(resetOpenProfile());
              dispatch(resetOpenChat());
              dispatch(resetOpenAvatar());
              dispatch(resetOpenDescri());
              dispatch(fetchAsyncGetMyAvatar());
              refreshPage();
              }}>
            </button>
            <button className={styles.core_title}
              onClick={async(e) => {
              dispatch(resetOpenProfile());
              dispatch(resetOpenChat());
              dispatch(resetOpenAvatar());
              dispatch(resetOpenDescri());
              dispatch(fetchAsyncGetMyAvatar());
              refreshPage();
              }}><span>
               UUROCO</span>
            </button>
          </div>
          {!isFirstRef && !isAvailable && <ErrorText />}
          {isAvailable && profile?.nickName  ? (
          <>
            <div className={styles.core_logo}>
            <div className={styles.core_logocont}>
              <button
                className={styles.core_btnChat}
                onClick={(e) => {
                  dispatch(setOpenChat());
                  dispatch(fetchAsyncGetMyChat());
                  dispatch(fetchAsyncGetMyAvatar());
                  dispatch(resetOpenProfile());
                  dispatch(setOpenLocation());
                  getCurrentPosition();
                  PostCurrentPosition(e);
                  dispatch(fetchAsyncGetPosts());
                  dispatch(resetOpenAvatar());
                  dispatch(resetOpenDescri());
                }}
              >        
              </button>
              <button
                className={styles.core_btnAvatar}
                onClick={() => {
                  dispatch(setOpenAvatar());
                  dispatch(fetchAsyncGetMyAvatar());
                  dispatch(fetchAsyncGetPosts());
                  dispatch(resetOpenProfile());
                  dispatch(resetOpenChat());
                  dispatch(resetOpenDescri());
                }}
              >
              </button>
              </div>
              <div className={styles.core_setprofilecontener}>
              <div className={styles.core_logout}>
                {isLoadingAuth && <CircularProgress />}
                <Button
                  onClick={() => {
                    logout({ returnTo: window.location.origin });
                    localStorage.removeItem("localJWT");
                    dispatch(editNickname(""));
                    dispatch(resetOpenProfile());
                    dispatch(resetOpenChat());
                    dispatch(setOpenSignIn());
                    dispatch(resetOpenAvatar());
                    dispatch(resetOpenDescri());
                  }}
                >
                Logout
                </Button>
              </div>
              <div className={styles.core_setprofile}>
                <button
                  className={styles.core_btnModal}
                  onClick={() => {
                    dispatch(setOpenProfile());
                    dispatch(resetOpenChat());
                    dispatch(fetchAsyncGetPosts());
                    dispatch(resetOpenAvatar());
                    dispatch(resetOpenDescri());
                  }}
                >
                <StyledBadge
                  overlap="circle"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  variant="dot"
                >
                  <Avatar alt="who?" src={profile.img} />{" "}
                </StyledBadge>
                </button>
              
              </div>
              </div></div>
          </>
          ) : (
            <div className={styles.core_logout}>
              <Button
                  onClick={() => {
                    logout({ returnTo: window.location.origin });
                    localStorage.removeItem("localJWT");
                  }}
                >
                Logout
                </Button>
            </div>
          )}
        </div>
        {profile?.nickName && (
        <>
          <div className={styles.core_bodys}>
            <div className={styles.core_image}> 
              <div className={styles.core_posts}>
                <Grid 
                container
                direction="row-reverse"
                justify="flex-start"
                alignItems="center"
                >
                  {posts
                    .slice(0)
                    .map((post) => (
                      <Grid key={post.id} >
                        <Post
                          postId={post.id}
                          chatp={post.chatp}
                          answerp={post.answerp}
                          loginId={profile.userProfile}
                          imageUrl={post.img}
                          postliked={post.postliked}
                          postuser={post.postuser}
                          nickNamep={post.nickNamep}
                        />
                      </Grid>
                  ))} 
                </Grid>  
                  <div className={styles.core_setmodels}><Setcoremodels /></div>                        
              </div>             
            </div >
          </div>
          <Adsense/>
          <div className={styles.core_footer}>
            <div className={styles.core_footercontener}>
              <div className={styles.core_hp}>
                <a href={"https://uuroco.com"} rel="noreferrer">
                  uuroco HP
                </a>
              </div>
              <div className={styles.core_contact}>
                <a href={"https://tayori.com/form/bbae405109860f8a23b1a388f7831780852fdd91"} rel="noreferrer">
                  CONTACT
                </a>
              </div>
              <div className={styles.core_qqcontener}>
                <button className={styles.core_footertos}
                  onClick={async () => {                
                  dispatch(setOpenTos());
                  }}
                >
                  利用規約
                </button>
                <button className={styles.core_footerdescri}
                  onClick={() => {
                  dispatch(setOpenDescri());
                  }}
                >
                  PRIVACY POLICY
                </button>
              </div>
            </div>
          </div>     
        </>
        )}
      </div>
    </div>
  );
};

export default Core;