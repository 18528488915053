import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import axios from "axios";
import { PROPS_UPDAVATAR, PROPS_MOVIES } from "../types";

const apiUrl = process.env.REACT_APP_DEV_API_URL;

export const fetchAsyncCreateMov = createAsyncThunk(
  "avatar/post",
  async (movie: PROPS_MOVIES) => {
    const res = await axios.post(`${apiUrl}api/avatars/`, movie, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.localJWT}`,
      },
    });
    return res.data;
  }
);
export const fetchAsyncGetMyAvatar = createAsyncThunk("avatar/get", async () => {
  const res = await axios.get(`${apiUrl}api/myavatar/`, {
    headers: {
      Authorization: `JWT ${localStorage.localJWT}`,
    },
  });
  return res.data[0];
});
export const fetchAsyncGetAvatars = createAsyncThunk("avatars/get", async () => {
  const res = await axios.get(`${apiUrl}api/avatars/`, {
    headers: {
      Authorization: `JWT ${localStorage.localJWT}`,
    },
  });
  return res.data;
});
export const fetchAsyncUpdateAvatars = createAsyncThunk(
  "avatar/put",
  async (avatar: PROPS_UPDAVATAR) => {
    const uploadData = new FormData();
    uploadData.append("movies", avatar.movies);
    const res = await axios.put(`${apiUrl}api/avatars/${avatar.id}/`, uploadData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.localJWT}`,
      },
    });
      return res.data;
    }
);

export const avatarSlice = createSlice({
  name: "avatar",
  initialState: {
    isLoadingAvatar: false,
    openAvatar: false,
    myavatar: 
      {
        id: 0,
        movies: "",
        userAvatar: 0,
      },
    avatars: [
      {
        id: 0,
        movies: "",
        userAvatar: 0,
      },
    ]
  },
  reducers: {
    fetchAvatarStart(state) {
      state.isLoadingAvatar = true;
    },
    fetchAvatarEnd(state) {
      state.isLoadingAvatar = false;
    },
    setOpenAvatar(state) {
      state.openAvatar = true;
    },
    resetOpenAvatar(state) {
      state.openAvatar = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAsyncGetMyAvatar.fulfilled, (state, action) => {
      state.myavatar = action.payload;
    });
    builder.addCase(fetchAsyncGetAvatars.fulfilled, (state, action) => {
      state.avatars = action.payload;
    });
    builder.addCase(fetchAsyncUpdateAvatars.fulfilled, (state, action) => {
      state.myavatar = action.payload;
      state.avatars = state.avatars.map((ava) =>
        ava.id === action.payload.id ? action.payload : ava
      );
    });
  },
});

export const {
  fetchAvatarStart,
  fetchAvatarEnd,
  setOpenAvatar,
  resetOpenAvatar,
} = avatarSlice.actions;

export const selectIsLoadingAvatar = (state: RootState) =>
  state.avatar.isLoadingAvatar;
export const selectOpenAvatar = (state: RootState) => state.avatar.openAvatar;
export const selectAvatar = (state: RootState) => state.avatar.myavatar;
export const selectAvatars = (state: RootState) => state.avatar.avatars;

export default avatarSlice.reducer;