import React from "react";
import styles from "./Post.module.css"
import { Avatar, Checkbox } from "@material-ui/core";
import { Grade, GradeTwoTone } from "@material-ui/icons";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "../../app/store";
import { selectProfiles } from "../auth/authSlice";
import {
  fetchPostStart,
  fetchPostEnd,
  fetchAsyncPatchPostLiked,
} from "./postSlice";
import { PROPS_POST } from "../types";

const Post: React.FC<PROPS_POST> = ({
  postId,
  loginId,
  answerp,
  postliked,
  nickNamep,
  postuser,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const profiles = useSelector(selectProfiles);
  const prof = profiles.filter((prof) => {
    return prof.userProfile + '' === postuser;
  });

  const handlerPostLiked = async () => {
    const packet = {
      id: postId,
      answerp: answerp,
      current: postliked,
      new: loginId,
    };
    await dispatch(fetchPostStart());
    await dispatch(fetchAsyncPatchPostLiked(packet));
    await dispatch(fetchPostEnd());
  };
  return (
    <div className={styles.post}>
      <div className={styles.post_header}> 
        <Avatar 
          className={styles.post_avatar} 
          src={prof[0]?.img}
        />
      </div>
        <div className={styles.post_text}>
          <div className={styles.speech}>
            <div className={styles.nickNamep}>{nickNamep}</div>        
            <p>{answerp}</p>        
            <div className={styles.speechn}></div>
          </div>
        </div>
        <Checkbox
            className={styles.post_checkBox}
            icon={<GradeTwoTone />}
            checkedIcon={<Grade className={styles.post_checkedIcon}/>}        
            checked={postliked.some((postlike) => postlike === loginId)}
            onChange={handlerPostLiked}
        />
        <AvatarGroup max={2}>
            {postliked.map((postlike) => (
              <Avatar
                className={styles.post_avatarGroup}
                key={postlike}
                src={profiles.find((prof) => prof.userProfile === postlike)?.img}
              />
            ))}
        </AvatarGroup>                                           
        <br />
        <br />
    </div>
  );
};

export default Post;