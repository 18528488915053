import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import axios from "axios";
import { PROPS_POSTGEO } from "../types";
const apiUrl = process.env.REACT_APP_DEV_API_URL;

export const fetchAsyncGetGeo = createAsyncThunk(
  "location/get", async () => {
  const res = await axios.get(`${apiUrl}api/location/`, {
    headers: {
      Authorization: `JWT ${localStorage.localJWT}`,
    },
  });
  return res.data;
});

export const fetchAsyncPostGeo = createAsyncThunk(
  "location/post",
  async (getCurrentPosition: PROPS_POSTGEO) => {
    const res = await axios.post(`${apiUrl}api/location/`, getCurrentPosition, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.localJWT}`,
      },
    });
    return res.data;
  }
);

export const locationSlice = createSlice({
  name: "location",
  initialState: {
    isLoadingLocation: false,
    openLocation: false,
    locations: [
      {
      id: 1,
      userLocation: 1,
      created_on: "",
      latitude: 1,
      longitude: 1,
      },
    ],
  },
  reducers: {
    fetchLocationStart(state) {
      state.isLoadingLocation = true;
    },
    fetchLocationEnd(state) {
      state.isLoadingLocation = false;
    },
    setOpenLocation(state) {
      state.openLocation = true;
    },
    resetOpenLocation(state) {
      state.openLocation = false;
    },
    
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAsyncGetGeo.fulfilled, (state, action) => {
      return {
        ...state,
        locations: action.payload,
      };
    });
    
    builder.addCase(fetchAsyncPostGeo.fulfilled, (state, action) => {
      state.locations = action.payload;
    });
  },
});

export const {
  fetchLocationStart,
  fetchLocationEnd,
  setOpenLocation,
  resetOpenLocation,
} = locationSlice.actions;

export const selectIsLoadingLocation = (state: RootState) =>
  state.location.isLoadingLocation;
export const selectOpenLocation = (state: RootState) => state.location.openLocation;
export const selectLocation = (state: RootState) => state.location.locations;

export default locationSlice.reducer;