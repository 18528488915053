import React from "react";
import Modal from "react-modal";
import styles from "./Auth.module.css";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "../../app/store";
import {
  selectOpenTos,
  resetOpenTos,
} from "../auth/authSlice";
import { Button, withStyles } from "@material-ui/core";

const customStyles = {
content: {
    top: "55%",
    left: "50%",
    width: "100%",
    height: "85%",
    padding: "1em",
    transform: "translate(-48.5%, -55%)",
  },
};
const StyledButton = withStyles({
  root: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #010658 90%)',
    borderRadius: 10,
    border: 0,
    color: 'white',
    height: 48,
    padding: '0 30px',
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);
const Tos: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const openTos = useSelector(selectOpenTos);
  return (
    <>
      <Modal
        isOpen={openTos}
        style={customStyles}
      >
        <div className={styles.auth_signUp}>
          <h1 className={styles.auth_title}>利用規約</h1>
          <br />
                <div className={styles.auth_tos}>
                  <div className={styles.auth_toscenter}>この利用規約（以下，「本規約」といいます。）は，uuroco.jp（以下，「当サイト」といいます。）がこのウェブサイト上で提供するサービス（以下，「本サービス」といいます。）の利用条件を定めるものです。登録ユーザーの皆さま（以下，「ユーザー」といいます。）には，本規約に従って，本サービスをご利用いただきます。</div>
                      <h3 className={styles.auth_toshaeder}>第1条（適用）</h3>
                      <div className={styles.auth_toscenter}> {`1.本規約は，ユーザーと当サイトとの間の本サービスの利用に関わる一切の関係に適用されるものとします。    
                      2.当サイトは本サービスに関し，本規約のほか，ご利用にあたってのルール等，各種の定め（以下，「個別規定」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず，本規約の一部を構成するものとします。
                      3.本規約の規定が前条の個別規定の規定と矛盾する場合には，個別規定において特段の定めなき限り，個別規定の規定が優先されるものとします。`}</div>
                      <h3 className={styles.auth_toshaeder}>第2条（利用登録）</h3>
                      <div className={styles.auth_toscenter}>{`1.本サービスにおいては，登録希望者が本規約に同意の上，当サイトの定める方法によって利用登録を申請し，当サイトがこの承認を登録希望者に通知することによって，利用登録が完了するものとします。
                      2.当サイトは，利用登録の申請者に以下の事由があると判断した場合，利用登録の申請を承認しないことがあり，その理由については一切の開示義務を負わないものとします。
                        (1).利用登録の申請に際して虚偽の事項を届け出た場合
                        (2).本規約に違反したことがある者からの申請である場合
                        (3).その他，当サイトが利用登録を相当でないと判断した場合`}</div>
                      <h3 className={styles.auth_toshaeder}>第3条（ユーザーIDおよびパスワードの管理）</h3>
                      <div className={styles.auth_toscenter}>{`1.ユーザーは，自己の責任において，本サービスのユーザーIDおよびパスワードを適切に管理するものとします。
                      2.ユーザーは，いかなる場合にも，ユーザーIDおよびパスワードを第三者に譲渡または貸与し，もしくは第三者と共用することはできません。当サイトは，ユーザーIDとパスワードの組み合わせが登録情報と一致してログインされた場合には，そのユーザーIDを登録しているユーザー自身による利用とみなします。
                      3.ユーザーID及びパスワードが第三者によって使用されたことによって生じた損害は，当サイトに故意又は重大な過失がある場合を除き，当サイトは一切の責任を負わないものとします。`}</div>
                      <h3 className={styles.auth_toshaeder}>第4条（利用料金および支払方法）</h3>
                      <div className={styles.auth_toscenter}>{`1.ユーザーは，本サービスの有料部分の対価として，当サイトが別途定め，本ウェブサイトに表示する利用料金を，当サイトが指定する方法により支払うものとします。
                      2.ユーザーが利用料金の支払を遅滞した場合には，ユーザーは年14．6％の割合による遅延損害金を支払うものとします。`}</div>
                      <h3 className={styles.auth_toshaeder}>第5条（禁止事項）</h3>
                      <div className={styles.auth_toscenter}>{`ユーザーは，本サービスの利用にあたり，以下の行為をしてはなりません。
                        1.法令または公序良俗に違反する行為
                        2.犯罪行為に関連する行為
                        3.当サイト，本サービスの他のユーザー，または第三者のサーバーまたはネットワークの機能を破壊したり，妨害したりする行為
                        4.当サイトのサービスの運営を妨害するおそれのある行為
                        5.他のユーザーに関する個人情報等を収集または蓄積する行為
                        6.不正アクセスをし，またはこれを試みる行為
                        7.他のユーザーに成りすます行為
                        8.当サイトのサービスに関連して，反社会的勢力に対して直接または間接に利益を供与する行為
                        9.当サイト，本サービスの他のユーザーまたは第三者の知的財産権，肖像権，プライバシー，名誉その他の権利または利益を侵害する行為
                        10.以下の表現を含み，または含むと当サイトが判断する内容を本サービス上に投稿し，または送信する行為
                            (1).過度に暴力的な表現
                            (2).露骨な性的表現
                            (3).人種，国籍，信条，性別，社会的身分，門地等による差別につながる表現
                            (4).自殺，自傷行為，薬物乱用を誘引または助長する表現
                            (5).その他反社会的な内容を含み他人に不快感を与える表現
                        11.以下を目的とし，または目的とすると当サイトが判断する行為
                            (1).営業，宣伝，広告，勧誘，その他営利を目的とする行為（当サイトの認めたものを除きます。）
                            (2).性行為やわいせつな行為を目的とする行為
                            (3).面識のない異性との出会いや交際を目的とする行為
                            (4).他のユーザーに対する嫌がらせや誹謗中傷を目的とする行為
                            (5).当サイト，本サービスの他のユーザー，または第三者に不利益，損害または不快感を与えることを目的とする行為
                            (6).その他本サービスが予定している利用目的と異なる目的で本サービスを利用する行為
                        12.宗教活動または宗教団体への勧誘行為
                        13.その他，当サイトが不適切と判断する行為`}</div>
                      <h3 className={styles.auth_toshaeder}>第6条（本サービスの提供の停止等）</h3>
                      <div className={styles.auth_toscenter}>{`1.当サイトは，以下のいずれかの事由があると判断した場合，ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。
                            (1).本サービスにかかるコンピュータシステムの保守点検または更新を行う場合
                            (2).地震，落雷，火災，停電または天災などの不可抗力により，本サービスの提供が困難となった場合
                            (3).コンピュータまたは通信回線等が事故により停止した場合
                            (4).その他，当サイトが本サービスの提供が困難と判断した場合
                      2.当サイトは，本サービスの提供の停止または中断により，ユーザーまたは第三者が被ったいかなる不利益または損害についても，一切の責任を負わないものとします。`}</div>
                      <h3 className={styles.auth_toshaeder}>第7条（著作権）</h3>
                      <div className={styles.auth_toscenter}>{`1.ユーザーは，自ら著作権等の必要な知的財産権を有するか，または必要な権利者の許諾を得た文章，画像や映像等の情報に関してのみ，本サービスを利用し，投稿ないしアップロードすることができるものとします。
                      2.ユーザーが本サービスを利用して投稿ないしアップロードした文章，画像，映像等の著作権については，当該ユーザーその他既存の権利者に留保されるものとします。ただし，当サイトは，本サービスを利用して投稿ないしアップロードされた文章，画像，映像等について，本サービスの改良，品質の向上，または不備の是正等ならびに本サービスの周知宣伝等に必要な範囲で利用できるものとし，ユーザーは，この利用に関して，著作者人格権を行使しないものとします。
                      3.前項本文の定めるものを除き，本サービスおよび本サービスに関連する一切の情報についての著作権およびその他の知的財産権はすべて当サイトまたは当サイトにその利用を許諾した権利者に帰属し，ユーザーは無断で複製，譲渡，貸与，翻訳，改変，転載，公衆送信（送信可能化を含みます。），伝送，配布，出版，営業使用等をしてはならないものとします。`}</div>
                      <h3 className={styles.auth_toshaeder}>第8条（利用制限および登録抹消）</h3>
                      <div className={styles.auth_toscenter}>{`1.当サイトは，ユーザーが以下のいずれかに該当する場合には，事前の通知なく，投稿データを削除し，ユーザーに対して本サービスの全部もしくは一部の利用を制限しまたはユーザーとしての登録を抹消することができるものとします。
                          (1).本規約のいずれかの条項に違反した場合
                          (2).登録事項に虚偽の事実があることが判明した場合
                          (3).決済手段として当該ユーザーが届け出たクレジットカードが利用停止となった場合
                          (4).料金等の支払債務の不履行があった場合
                          (5).当サイトからの連絡に対し，一定期間返答がない場合
                          (6).本サービスについて，最終の利用から一定期間利用がない場合
                          (7).その他，当サイトが本サービスの利用を適当でないと判断した場合
                        2.前項各号のいずれかに該当した場合，ユーザーは，当然に当サイトに対する一切の債務について期限の利益を失い，その時点において負担する一切の債務を直ちに一括して弁済しなければなりません。
                        3.当サイトは，本条に基づき当サイトが行った行為によりユーザーに生じた損害について，一切の責任を負いません。`}</div>
                      <h3 className={styles.auth_toshaeder}>第9条（退会）</h3>
                      <div className={styles.auth_toscenter}>ユーザーは，当サイトの定める退会手続により，本サービスから退会できるものとします。</div>
                      <h3 className={styles.auth_toshaeder}>第10条（保証の否認および免責事項）</h3>
                      <div className={styles.auth_toscenter}>{`1.当サイトは，本サービスに事実上または法律上の瑕疵（安全性，信頼性，正確性，完全性，有効性，特定の目的への適合性，セキュリティなどに関する欠陥，エラーやバグ，権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。
                      2.当サイトは，本サービスに起因してユーザーに生じたあらゆる損害について一切の責任を負いません。ただし，本サービスに関する当サイトとユーザーとの間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合，この免責規定は適用されません。
                      3.前項ただし書に定める場合であっても，当サイトは，当サイトの過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害のうち特別な事情から生じた損害（当サイトまたはユーザーが損害発生につき予見し，または予見し得た場合を含みます。）について一切の責任を負いません。また，当サイトの過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害の賠償は，ユーザーから当該損害が発生した月に受領した利用料の額を上限とします。
                      4.当サイトは，本サービスに関して，ユーザーと他のユーザーまたは第三者との間において生じた取引，連絡または紛争等について一切責任を負いません。`}</div>
                      <h3 className={styles.auth_toshaeder}>第11条（サービス内容の変更等）</h3>
                      <div className={styles.auth_toscenter}>当サイトは，ユーザーに通知することなく，本サービスの内容を変更しまたは本サービスの提供を中止することができるものとし，これによってユーザーに生じた損害について一切の責任を負いません。</div>
                      <h3 className={styles.auth_toshaeder}>第12条（利用規約の変更）</h3>
                      <div className={styles.auth_toscenter}>当サイトは，必要と判断した場合には，ユーザーに通知することなくいつでも本規約を変更することができるものとします。なお，本規約の変更後，本サービスの利用を開始した場合には，当該ユーザーは変更後の規約に同意したものとみなします。</div>
                      <h3 className={styles.auth_toshaeder}>第13条（個人情報の取扱い）</h3>
                      <div className={styles.auth_toscenter}>当サイトは，本サービスの利用によって取得する個人情報については，当サイト「プライバシーポリシー」に従い適切に取り扱うものとします。</div>
                      <h3 className={styles.auth_toshaeder}>第14条（通知または連絡）</h3>
                      <div className={styles.auth_toscenter}>ユーザーと当サイトとの間の通知または連絡は，当サイトの定める方法によって行うものとします。当サイトは,ユーザーから,当サイトが別途定める方式に従った変更届け出がない限り,現在登録されている連絡先が有効なものとみなして当該連絡先へ通知または連絡を行い,これらは,発信時にユーザーへ到達したものとみなします。</div>
                      <h3 className={styles.auth_toshaeder}>第15条（権利義務の譲渡の禁止）</h3>
                      <div className={styles.auth_toscenter}>ユーザーは，当サイトの書面による事前の承諾なく，利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し，または担保に供することはできません。</div>
                      <h3 className={styles.auth_toshaeder}>第16条（準拠法・裁判管轄）</h3>
                      <div className={styles.auth_toscenter}>{`1.本規約の解釈にあたっては，日本法を準拠法とします。
                      2.本サービスに関して紛争が生じた場合には，当サイトの本店所在地を管轄する裁判所を専属的合意管轄とします。`}</div>
                        <div className={styles.auth_toscenter}>以上</div>
                    <br />
                    <StyledButton
                      variant="contained"   
                      onClick={async () => {
                      await dispatch(resetOpenTos());
                    }}
                    >
                      確認&戻る
                    </StyledButton>
                  
          </div>        
        </div>
      </Modal>
    </>
  );
};

export default Tos;