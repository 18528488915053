import React from 'react';
import Modal from "react-modal";
import { AppDispatch } from "../../app/store";
import { useSelector, useDispatch } from "react-redux";
import { useAtom, atom } from 'jotai';
import { Canvas, extend } from "@react-three/fiber";
import styles from "./Avatar.module.css";
import { Formik, Field, Form } from "formik";
import { withStyles, Button } from "@material-ui/core";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
//import * as Yup from "yup";
import {
  fetchAvatarStart,
  selectOpenAvatar,
  fetchAvatarEnd,
  resetOpenAvatar,
  fetchAsyncUpdateAvatars,
  fetchAsyncGetMyAvatar,
  fetchAsyncGetAvatars,
  selectAvatar,
} from "./avatarSlice"

extend({ Canvas })

const StyledButton = withStyles({
  root: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: 40,
    padding: '0 30px',
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    left: "5%",
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);

const customStyles = {
  content: {
    top: "55%",
    left: "50%",
    width: "100%",
    height: "85%",
    padding: "2em",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fdd2cc"
  },
};

const photoAtom = atom('fedefault0709.png');
const textAtom = atom("");
const hpAtom = atom("");
const checkedAtom = atom("");
const SetAvatar: React.FC = () => {
  const openAvatar = useSelector(selectOpenAvatar);
  const dispatch: AppDispatch = useDispatch();
  const [photo, setPhoto] = useAtom(photoAtom)
  const [texts, setTexts] = useAtom(textAtom);
  const [hp, setHp] = useAtom(hpAtom);
  const [checked, setChecked] = useAtom(checkedAtom);
  const avatar = useSelector(selectAvatar);
  
  const refreshPage = () => {
    window.location.reload(false);
  }
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 10,
    slidesToScroll: 10,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 8,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          initialSlide: 5
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      }
    ]
  };
  
  return (
    <>
      <Modal
        isOpen={openAvatar}
        onRequestClose={async () => {
          await dispatch(resetOpenAvatar());
        }}
        style={customStyles}
      >
        <Formik
          initialValues={{ id: avatar.id, movies: avatar.movies, checked: "", }}
          onSubmit={async (values) => {
            await dispatch(fetchAvatarStart());
            const result = await dispatch(fetchAsyncUpdateAvatars(values));
            if (fetchAsyncUpdateAvatars.fulfilled.match(result)) {
              await dispatch(fetchAsyncGetMyAvatar());
              await dispatch(fetchAsyncGetAvatars());
            }
            await dispatch(fetchAvatarEnd());
            await dispatch(resetOpenAvatar());
            refreshPage();
          }}
          >
          {({ 
            handleChange,
            values,     
            isValid,      
            handleBlur,
          }) => (
            <div>
              <Form>
                <br />
                <label >
                  <Field
                    type="text"
                    name="checked"
                    hidden={true}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={checked} {...values.checked}             
                  /> 
                </label>
                <div className={styles.avatar_change}>
                  <div className={styles.sliderContainer}>
                    <Slider {...settings}>
                      <div className={styles.avatar_changegroup1} role="group">      
                      <label>
                        <Field    
                          hidden={true} type="radio" name="movies" value='/fedefault' 
                          onBlur={handleBlur} onChange={handleChange} onClick={() => {
                          setPhoto("fedefault0709.png");
                          setTexts('default1\n初期アバター１\n今後はオリジナルデザインアバターや\n実際に存在するブランドとのコラボができれば\n着せ替えファッションアバターを配信予定。\n下記のHPは製作者HPです。');
                          setHp("https://uuroco.com");
                          setChecked('true')
                        }}/>
                            <div className={styles.avatar_first}>default1</div>
                      </label>
                      <label>
                        <Field           
                          hidden={true} type="radio" name="movies" value='/mendefault' 
                          onBlur={handleBlur} onChange={handleChange} onClick={() => {
                          setPhoto("mendefault0709.png");
                          setTexts('default2\n初期アバター１\n今後はオリジナルデザインアバターや\n実際に存在するブランドとのコラボができれば\n着せ替えファッションアバターを配信予定。\n下記のHPは製作者HPです。');
                          setHp("https://uuroco.com");
                          setChecked('true')
                        }}/>
                          <div className={styles.avatar_second}>default2</div>
                      </label>
                      </div>
                      <div className={styles.avatar_changegroup2}>
                        <div className={styles.avatar_basic0}></div>
                        <div className={styles.avatar_basic1}></div>     
                      </div>
                      <div className={styles.avatar_changegroup2}>
                        <div className={styles.avatar_basic1}></div>
                        <div className={styles.avatar_basic0}></div>     
                      </div>
                      <div className={styles.avatar_changegroup2}>
                        <div className={styles.avatar_basic0}></div>
                        <div className={styles.avatar_basic1}></div>     
                      </div>
                      <div className={styles.avatar_changegroup2}>
                        <div className={styles.avatar_basic1}></div>
                        <div className={styles.avatar_basic0}></div>     
                      </div>
                      <div className={styles.avatar_changegroup2}>
                        <div className={styles.avatar_basic0}></div>
                        <div className={styles.avatar_basic1}></div>     
                      </div>
                      <div className={styles.avatar_changegroup2}>
                        <div className={styles.avatar_basic1}></div>
                        <div className={styles.avatar_basic0}></div>     
                      </div> 
                      <div className={styles.avatar_changegroup2}>
                        <div className={styles.avatar_basic0}></div>
                        <div className={styles.avatar_basic1}></div>     
                      </div> 
                      <div className={styles.avatar_changegroup2}>
                        <div className={styles.avatar_basic1}></div>
                        <div className={styles.avatar_basic0}></div>     
                      </div>  
                      <div className={styles.avatar_changegroup2}>
                        <div className={styles.avatar_basic0}></div>
                        <div className={styles.avatar_basic1}></div>     
                      </div>                   
                    </Slider>
                  </div>
                  <div className={styles.avatar_stage}>
                    <div className={styles.avatar_setmodels}>
                      <img src={process.env.PUBLIC_URL + photo} alt="" width="50%"></img >
                    </div>
                    <div className={styles.avatar_texts}>
                      {texts}
                      <a href={hp} rel="noreferrer">
                        AvatarDesignHP
                      </a>
                    </div>
                  </div>
                  
                  <StyledButton
                    variant="contained"
                    disabled={!isValid}
                    type="submit"
                  >
                      Make this Avatar
                  </StyledButton>
                </div>
              </Form> 
            </div>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default SetAvatar;
