import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import axios from "axios";
import { PROPS_POSTCHAT } from "../types";
const apiUrl = process.env.REACT_APP_DEV_API_URL;

export const fetchAsyncGetMyChat = createAsyncThunk(
  "mychat/get", async () => {
  const res = await axios.get(`${apiUrl}api/mychat/`, {
    headers: {
      Authorization: `JWT ${localStorage.localJWT}`,
    },
  });
  return res.data;
}
);

export const fetchAsyncPostChat = createAsyncThunk(
  "chats/post",
  async (postChat: PROPS_POSTCHAT) => {
    const res = await axios.post(`${apiUrl}api/chat/`, postChat, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.localJWT}`,
      },
    });
    return res.data;
  }
);

export const chatSlice = createSlice({
  name: "chat",
  initialState: {
    isLoadingChat: false,
    openChat: false,
    mychat: [
      {
      id: 1,
      chatx: "",
      answer: "",
      userChat: 1,
      created_on: "",
      },
    ],
    
    chats: [
      {
        id: 1,
        chatx: "",
        answer: "",
        userChat: 1,
        created_on: "",
      },
    ],
  },
  reducers: {
    fetchChatStart(state) {
      state.isLoadingChat = true;
    },
    fetchChatEnd(state) {
      state.isLoadingChat = false;
    },
    setOpenChat(state) {
      state.openChat = true;
    },
    resetOpenChat(state) {
      state.openChat = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAsyncGetMyChat.fulfilled, (state, action) => {
      return {
        ...state,
        mychat: action.payload,
      };
    });
    builder.addCase(fetchAsyncPostChat.fulfilled, (state, action) => {
      return {
        ...state,
        chats: [...state.chats, action.payload],
      };
    });
    
  },
});

export const {
  fetchChatStart,
  fetchChatEnd,
  setOpenChat,
  resetOpenChat,
} = chatSlice.actions;

export const selectIsLoadingChat = (state: RootState) =>
  state.chat.isLoadingChat;
export const selectOpenChat = (state: RootState) => state.chat.openChat;
export const selectChat = (state: RootState) => state.chat.mychat;
export const selectChats = (state: RootState) => state.chat.chats;

export default chatSlice.reducer;