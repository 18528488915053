import React ,{ useEffect, useState, useCallback } from "react";
import { AppDispatch } from "../../app/store";
import { useSelector, useDispatch } from "react-redux";
import styles from "./Auth.module.css";
import Modal from "react-modal";
import { fetchAsyncGetMyAvatar, fetchAsyncCreateMov } from "../avatar/avatarSlice";
import foo from "../img/au/authhaikei2.png";
import { CircularProgress } from "@material-ui/core";
import { fetchAsyncGetPosts } from "../post/postSlice";
import { fetchAsyncGetMyChat } from "../chat/chatSlice";
import {
  setOpenTos,
  selectOpenSignIn,
  resetOpenSignIn,
  fetchCredStart,
  fetchCredEnd,
  fetchAsyncLogin,
  fetchAsyncRegister,
  fetchAsyncGetMyProf,
  fetchAsyncGetProfs,
  setOpenSecret,
  selectProfile,
  setOpenDescri,
  selectIsLoadingAuth,
} from "./authSlice";
import { useAuth0 } from "@auth0/auth0-react";

interface GMapWindow extends Window {
  adsbygoogle: any;
 }
  declare const window: GMapWindow;

const customStyles = {
  content: {
    top: "55%",
    left: "50%",
    width: "95%",
    height: "85%",
    transform: "translate(-50%, -55%)",
    backgroundImage: `url(${foo})`,
  },
};

const Adsense: React.FC = () => {
  useEffect(() => {
    if (window.adsbygoogle && process.env.NODE_ENV !== "development") {
        window.adsbygoogle.push({});
    }
  }, [])
  return(
    <ins className="adsbygoogle"
      style={{ "display": "block" }}
      data-ad-client={process.env.REACT_APP_GOOGLE_AD_CLIENT}
      data-ad-slot={process.env.REACT_APP_GOOGLE_AD_SLOT}
      data-ad-format="auto"
      data-full-width-responsive="true">
    </ins>
  );
};
const Auth: React.FC = () => {
  Modal.setAppElement("#root");
  const openSignIn = useSelector(selectOpenSignIn);
  const dispatch: AppDispatch = useDispatch();
  const { isAuthenticated, loginWithRedirect, user, getIdTokenClaims } = useAuth0();
  const [inCount, setInCount] = useState(0);
  const profile = useSelector(selectProfile);
  const namespace = 'https://uuroco.jp/';
  const [inC, setInC] = useState(false);
  const isLoadingAuth = useSelector(selectIsLoadingAuth);

  const postLogin = useCallback(async() => { 
    const packet = { email: user?.email!, password: user?.sub! };
    await dispatch(fetchCredStart());
    const result = await dispatch(fetchAsyncLogin(packet));
    if (fetchAsyncLogin.fulfilled.match(result)) {
      await dispatch(fetchAsyncGetMyAvatar());
      await dispatch(fetchAsyncGetProfs());
      await dispatch(fetchAsyncGetPosts());
      await dispatch(fetchAsyncGetMyChat());
      await dispatch(fetchAsyncGetMyProf());
    };
    await dispatch(fetchCredEnd());
    await dispatch(resetOpenSignIn());
  },[dispatch, user?.email, user?.sub]);

  const postRegister = useCallback(async() => { 
    const packet = { email: user?.email!, password: user?.sub! };
    await dispatch(fetchCredStart());
    const resultReg = await dispatch(fetchAsyncRegister(packet));
    if (fetchAsyncRegister.fulfilled.match(resultReg)) {
      await dispatch(fetchAsyncLogin(packet));
      await dispatch(fetchAsyncCreateMov({ movies: "/fedefault"}));
      dispatch(setOpenSecret());
    };
    if (fetchAsyncRegister.rejected.match(resultReg)) {
      await dispatch(fetchAsyncLogin(packet));
      await dispatch(fetchAsyncGetMyProf());
      await dispatch(fetchAsyncGetMyAvatar());
      await dispatch(fetchAsyncGetProfs());
      await dispatch(fetchAsyncGetPosts());
      await dispatch(fetchAsyncGetMyChat());
      if (!profile?.nickName) {
        dispatch(setOpenSecret());
      };
    };
    await dispatch(fetchCredEnd());
    await dispatch(resetOpenSignIn());
    
  },[dispatch, user?.email, user?.sub, profile?.nickName]);
  
  useEffect(() => {
    const fetchLogInCount = async () => {
      if (isAuthenticated) {
        const getCount = await getIdTokenClaims().then(claims => {
          console.log(claims[`${namespace}loginCount`]);
          return Number(claims[`${namespace}loginCount`]);
        });
        setInCount(getCount);
        setInC(true)
      };
    };
    fetchLogInCount();
  },[getIdTokenClaims, isAuthenticated, inCount]);

  useEffect(() => {
    const fetchLogInCount = async () => {
      if (inC) {
        if (inCount === 1 ){
        postRegister();
        } if (inCount > 1 ) {
        postLogin();}
      };
    };
    fetchLogInCount();
  },[inC, inCount, postRegister, postLogin]);
  
  return (
    <>
      <Modal
        isOpen={openSignIn}
        style={customStyles}
      >
      <div className={styles.auth_bodys}>
      <div className={styles.auth_samplebeta}>sampleベータ版</div>
        {isLoadingAuth && <CircularProgress />}
        <button 
          className={styles.auth_loginimg}
            onClick={() => {
            dispatch(loginWithRedirect);
          }}>
        </button>
        <div className={styles.auth_secondback}>
          <div className={styles.auth_secondtoptext}>SimpleVirtualSNS</div>
          <div className={styles.auth_secondcontener1}>
            <div className={styles.auth_secondimg1}></div>
            <div className={styles.auth_secondtextcontener1}>
              <div className={styles.auth_secondtext1}>
                {`あなたの考えを
                理解したAIが
                SNSを始めます`}
              </div> 
              <div className={styles.auth_secondtext2}>
                {`AIとトークをすることで
                AIがあなたの考えを少しづつ
                覚えていきます`}
              </div>
            </div>
          </div>
          <div className={styles.auth_secondcontener2}>
            <div className={styles.auth_secondimg2}></div>
            <div className={styles.auth_secondtext3}>
              {`あなたの考えを覚えた
               AIがSNSを始めます`}
            </div>
          </div>
        </div>
        <div className={styles.auth_thridback}>
          <div className={styles.auth_thridcontener1}>
            <div className={styles.auth_thridtextcontener1}>
              <div className={styles.auth_thridtoptext}>
                {`自分を反映する`}
              </div>
              <div className={styles.auth_thridtext}>
                {`プロフィールやアバターの設定でより自分らしく`}
              </div>
            </div>
            <div className={styles.auth_thridcontener2}>
              <div className={styles.auth_thridimg1}></div>
              <div className={styles.auth_thridimg2}></div>
            </div>
          </div>
        </div>
      </div>  
      <Adsense/>
      <div className={styles.auth_footer}>
        <div className={styles.auth_footercontener}>
          <div className={styles.auth_hp}>
            <a href={"https://uuroco.com"} rel="noreferrer">
              uuroco HP
            </a>
          </div>
          <div className={styles.auth_contact}>
            <a href={"https://tayori.com/form/bbae405109860f8a23b1a388f7831780852fdd91"} rel="noreferrer">
              CONTACT
            </a>
          </div>
          <div className={styles.auth_qqcontener}>
            <button className={styles.auth_footertos}
              onClick={async () => {                
              dispatch(setOpenTos());
              }}
            >
              利用規約
            </button>
            <button className={styles.auth_footerdescri}
              onClick={() => {
              dispatch(setOpenDescri());
              }}
            >
              PRIVACY POLICY
            </button>
          </div>
        </div>     
      </div>
      </Modal>
    </>
  );
};

export default Auth;