import React, { useState, useEffect, useCallback, useRef } from "react";
import { PROPS_CHAT } from "../types";
import { Avatar, CircularProgress } from "@material-ui/core";
import Modal from "react-modal";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "../../app/store";
import styles from "./Chat.module.css"
import ReactPlayer from "react-player"
import { selectProfile } from "../auth/authSlice";
import { Provider } from 'jotai';
import {
  fetchChatStart,
  fetchChatEnd,
  selectChat,
  selectOpenChat,
  resetOpenChat,
  fetchAsyncGetMyChat,
  fetchAsyncPostChat,
  selectIsLoadingChat,
} from "./chatSlice";
import { selectAvatar } from "../avatar/avatarSlice"

const customStyles = {
  content: {
    top: "55%",
    left: "50%",
    width: "95%", 
    height: "80%",
    padding: "1em",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#f8e6c4",
  },
};

const Chats: React.FC<PROPS_CHAT> = ({
  chatx,
  answer,
}) => {
  const profile = useSelector(selectProfile);
  return (
    <div className={styles.chatContainer}>
      <div className={styles.messageContainer} >
        <div className={styles.user}>
          <div className={styles.usertext}>
                    
            <div className={styles.usercard}>
              <div className={styles.usercardn}></div> 
              <div className={styles.nickName}>{profile.nickName}</div>
                <p>{chatx} </p>
              
            </div>
          </div>
          <div className={styles.userheader}>
            <Avatar className={styles.useravatar} src={profile.img} />
          </div>                    
        </div>
        <div className={styles.bot}>
          <div className={styles.botheader}>     
          </div>  
          <div className={styles.bottext}>   
            <div className={styles.botcard}>
              
                <p>Avatar</p>
                <p>{answer}</p>    
            </div>
          </div>                        
        </div>   
      </div>
    </div>  
  );
};

const PostChat: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const openNewChat = useSelector(selectOpenChat);
  const isLoadingChat = useSelector(selectIsLoadingChat);
  const mychat = useSelector(selectChat);
  const [chatx, setChatx] = useState("");
  const [userChat, setUserChat] = useState(1);
  const answer = useState("");
  const chatxEndRef = useRef<HTMLDivElement>(null);
  const avatar = useSelector(selectAvatar);
  const scrollToBottom = () => {
    if (chatxEndRef.current) {
      chatxEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  
  useEffect(scrollToBottom);
  
  const getChat = (fetchAsyncGetMyChat());
  const getChats = useCallback( () => getChat, [getChat])
  useEffect(() => {
    const fetchBootLoader = async () => {
      if (openNewChat) {
        getChats();
        
        return null;
      }
    };
    fetchBootLoader();
  }, [getChats, openNewChat]);
 
  const postChat = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    const packet = { userChat: userChat, chatx: chatx, answer: answer };
    await dispatch(fetchChatStart());
    await dispatch(fetchAsyncPostChat(packet));
    await dispatch(getChats());
    await dispatch(fetchChatEnd());
    setChatx("");
    setUserChat(1);
  };
  
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChatx(e.target.value);
  };
  
  return (
    <div>   
        <>        
          <Modal
            isOpen={openNewChat}
            onRequestClose={async () => {
            await dispatch(resetOpenChat());
          }}
          style={customStyles}
          >
            <div className={styles.chats}>
              <div className={styles.chat_box}>
                {mychat
                  .map((chat) => (
                    <div key={chat.id}>
                      <Chats
                        chatx={chat.chatx}
                        answer={chat.answer}
                        userChat={chat.userChat}
                        id={chat.id}   
                      />
                    </div>
                ))}
              </div>                  
              <div ref={chatxEndRef} />
              <div className={styles.chat_footer}>
                <div className={styles.chat_playerwrapper}>
                  <Provider>
                    <ReactPlayer
                      url={avatar.movies + '.m3u8'}
                      width="60vmin"
                      height="40vmin"                   
                      playing={true}
                      muted={true}
                      
                      playsinline={true}
                  />
                  </Provider>
                </div>
                {isLoadingChat && <CircularProgress />}
                <div className={styles.chat_inputwrapper}>                                               
                  <div className={styles.chat_input}>
                    <input
                      type="text"
                      name="chatx"
                      value={chatx}
                      onChange={(ev) => handleInputChange(ev)}
                      placeholder="ねえねえ"
                      required
                    />
                  </div>
                  <button
                    className={styles.chat_button}
                  onClick={postChat}
                  type="submit">                        
                  </button>
                </div>          
              </div>
            </div>
          </Modal>
        </>  
    </div>
  );  
};

export default PostChat;