import React from "react";
import Modal from "react-modal";
import styles from "./Auth.module.css";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "../../app/store";
import { TextField, Button, Checkbox, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, withStyles } from "@material-ui/core";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import {
  fetchCredStart,
  fetchCredEnd,
  selectOpenSecret,
  resetOpenSecret,
  fetchAsyncCreateSec,
  fetchAsyncGetMyProf,
  fetchAsyncGetProfs,
  setOpenTos,
} from "../auth/authSlice";
import { fetchAsyncGetMyAvatar } from "../avatar/avatarSlice";
import { fetchAsyncGetPosts } from "../post/postSlice";
import { fetchAsyncGetMyChat } from "../chat/chatSlice";

const customStyles = {
  content: {
    top: "55%",
    left: "50%",
    width: 280,
    height: "50vh",
    padding: "50px",
    transform: "translate(-50%, -50%)",
  },
  
};
const StyledButton = withStyles({
  root: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: 48,
    padding: '0 30px',
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);
const SetProfile: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const openSecret = useSelector(selectOpenSecret);
  return (
    <>
      <Modal
        isOpen={openSecret}
        onRequestClose={async () => {
          await dispatch(resetOpenSecret());
        }}
        style={customStyles}
      >
        <Formik
          initialValues={{ nickName: "",  age: [], checked: false, gender: "" }}
          onSubmit={async (values) => {
            await dispatch(fetchCredStart());
            const result = await dispatch(fetchAsyncCreateSec(values));
            if (fetchAsyncCreateSec.fulfilled.match(result)) {
              await dispatch(fetchAsyncGetMyAvatar());
              await dispatch(fetchAsyncGetProfs());
              await dispatch(fetchAsyncGetPosts());
              await dispatch(fetchAsyncGetMyChat());
              await dispatch(fetchAsyncGetMyProf());          
            }
            await dispatch(fetchCredEnd());
            await dispatch(resetOpenSecret());
          }}

          validationSchema={Yup.object().shape({
            nickName: Yup.string().required("入力をお願い致します。"),
            age: Yup.number().required("年齢は数値での入力をお願い致します。").max(99),
            checked: Yup.boolean().oneOf([true], "ご確認をお願い致します。"),
            gender: Yup.string().required("選択お願い致します。"),
          })}
        >
          {({
            handleChange,
            values,
            errors,
            touched,
            isValid,
            handleBlur,
          }) => (
            <div>
              <Form>
                <div className={styles.auth_signUp}>
                  <h1 className={styles.auth_profiletitle}>PROFILE</h1> 
                  <br />
                  <TextField
                    placeholder="nickName"
                    type="text"
                    name="nickName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.nickName}
                  />          
                  <br/>
                  {touched.nickName && errors.nickName ? (
                    <div className={styles.auth_error}>{errors.nickName}</div>
                  ) : null}
                  <br />
                  <TextField
                    type="text"
                    name="age"
                    placeholder="age（公開されません）"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.age}
                  />
                  {touched.age && errors.age ? (
                    <div className={styles.auth_error}>{errors.age}</div>
                  ) : null}
                  <br />
                  <br />
                  <label>
                      <Field
                        as={Checkbox}
                        name="checked"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.checked}/>
                      利用規約を了承。（必ずリンクから利用規約をご確認をお願い致します。）
                  </label>
                  {touched.checked && errors.checked ? (
                    <div className={styles.auth_error}>{errors.checked}</div>
                  ) : null}
                  <span
                    className={styles.auth_text}
                    onClick={async () => {                
                      await dispatch(setOpenTos());
                    }}
                  >
                    利用規約
                  </span>
                  <br />
                  <br />
                  <FormControl component="label">
                    <FormLabel component="label">心のGender（公開されません）</FormLabel>
                    <RadioGroup
                      name="gender"
                      value={values.gender}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <FormControlLabel
                        value="Man"
                        control={<Radio />}
                        label="Man"
                      />
                      <FormControlLabel
                        value="Lady"
                        control={<Radio />}
                        label="Lady"
                      />
                      <FormControlLabel
                        value="どちらでもない"
                        control={<Radio />}
                        label="どちらでもない"
                      />

                    </RadioGroup>
                  </FormControl>
                  {touched.gender && errors.gender ? (
                    <div className={styles.auth_error}>{errors.gender}</div>
                  ) : null}
                  <br />
                  <br />             
                  <StyledButton
                    variant="contained"             
                    disabled={!isValid}
                    type="submit"       
                    >
                    perfect
                  </StyledButton>
                </div>
              </Form>            
            </div>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default SetProfile;